<template>
  <b-container fluid class="report-wrapper">
    <iq-card class="mt-5">
        <template :id="index">
            <b-overlay :show="unitLoad">
              <b-row :class="parseInt(service_id) === 1  ? 'dam' : ''" class="p-0 m-0" style="font-size: 14px !important">
                <slot v-if="parseInt(service_id) === 15">
                  <UncontrolledCropVarieties :isAdmin="true" :applicantData="applicationData" />
                </slot>
                <slot v-else-if="parseInt(service_id) === 5">
                  <FertilizerImportRegistration :isAdmin="true" :applicantData="applicationData" />
                </slot>
                <slot v-else-if="parseInt(service_id) === 6">
                  <FertilizerProductionRegistration :isAdmin="true" :applicantData="applicationData" />
                </slot>
                <slot v-else-if="parseInt(service_id) === 23">
                  <FertilizerDistributorRegistration :isAdmin="true" :applicantData="applicationData" />
                </slot>
                <slot v-else-if="parseInt(service_id) === 11">
                  <BadcCertificateFertilizer :isAdmin="true" :applicantData="applicationData" />
                </slot>
                <slot v-else-if="parseInt(service_id) === 19">
                  <BadcCertificate :isAdmin="true" :applicantData="applicationData" />
                </slot>
                <b-col v-else sm="12" style="margin: 0px; padding: 0px">
                  <div v-bind:class="alignment" style="margin:auto">
                    <grid-layout :layout.sync="layout"
                    :col-num="colNum"
                    :row-height="parseInt(service_id) === 1  ? 14 : 12"
                    :margin="parseInt(service_id) === 1  ? [5, 5] : [10, 10]"
                    :is-draggable="false"
                    :is-resizable="false"
                    :vertical-compact="true"
                    :use-css-transforms="true"
                    :style="pageType === 'landscape' ? 'margin: 0px 0px 0px 50px' : ''"
                    >
                      <grid-item style="height: auto !important" v-for="(item, dindex) in layout"
                        :x="item.x"
                        :y="item.y"
                        :w="item.w"
                        :h="item.h"
                        :i="item.i"
                        :key="dindex"
                      >
                      <b-row>
                        <slot v-if="parseInt(service_id) === 1">
                          <slot v-if="dindex === 47">
                            <b-row>{{ $n(paymentAmount)+ '/-' }}({{ parseFloat(paymentAmount) | convertNumberWords}} {{$t('globalTrans.taka ') + ' ' + $t('globalTrans.only')}})</b-row>
                          </slot>
                          <slot v-else>
                            <commonComponent :Sdata="formInputData[dindex]" :applicantData="applicationData" :layoutData="layoutData" :key="componentKey"/>
                          </slot>
                        </slot>
                        <slot v-else-if="parseInt(service_id) === 19">
                        <commonComponent :Sdata="formInputData[dindex]" :applicantData="applicationData" :layoutData="layoutData" :key="componentKey"/>
                        </slot>
                        <slot v-else>
                          <commonComponent :Sdata="formInputData[dindex]" :applicantData="applicationData" :layoutData="layoutData" :key="componentKey"/>
                        </slot>
                      </b-row>
                      </grid-item>
                    </grid-layout>
                  </div>
                </b-col>
              </b-row>
              <br/>
              <br/>
              <slot v-if="amountSuccess">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  id="form" @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('component_settings.approve_issue')}}</legend>
                      <b-row  class="p-0 m-0" style="font-size: 14px !important">
                        <b-col lg="4" sm="4" md="4">
                          <ValidationProvider name="Issue Date" vid="issue_date" rules="required">
                              <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="issue_date"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ (formData.type == 1) ? $t('li_step.issue_date') : $t('externalLrcpn.last_issue')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input
                                  id="datepicker"
                                  placeholder="yyyy-mm-dd"
                                  v-model="formData.issue_date"
                                  v-if="formData.type == 1"
                                  disabled
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <b-form-input
                                  id="datepicker"
                                  placeholder="yyyy-mm-dd"
                                  v-model="formData.old_expire_date"
                                  disabled
                                  v-else
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="4" md="4">
                          <ValidationProvider name="Expire Date" vid="expire_date" rules="required">
                              <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="expire_date"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('li_step.expire_date')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="datepicker"
                                  placeholder="yyyy-mm-dd"
                                  v-model="formData.expire_date"
                                  disabled
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="4" md="4" v-if="parseInt(formData.service_id) === 15 && formData.type === 1">
                          <ValidationProvider name="Generate Id" vid="generate_id" rules="required">
                              <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="generate_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('component_settings.generate_id')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  v-model="formData.generate_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <slot v-if="parseInt(formData.service_id) === 15 && formData.type === 1">
                        <b-row class="p-0 m-0" >
                          <b-col lg="4" sm="4" md="4">
                            <ValidationProvider name="Identifying Features" vid="1_identifying_features">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="1_identifying_features"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                  {{ $t('li_step.1_identifying_features')}}
                                </template>
                                <b-form-input
                                    v-model="formData.identifying_features_1"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="4" sm="4" md="4">
                            <ValidationProvider name="Identifying Features 2" vid="identifying_features_2">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="1_identifying_features"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('li_step.2_identifying_features')}}
                                </template>
                                <b-form-input
                                    v-model="formData.identifying_features_2"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="4" sm="4" md="4">
                            <ValidationProvider name="Identifying Features 3" vid="identifying_features_3">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="3_identifying_features"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('li_step.3_identifying_features')}}
                                </template>
                                <b-form-input
                                    v-model="formData.identifying_features_3"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="4" sm="4" md="4">
                            <ValidationProvider name="Identifying Features 4" vid="identifying_features_4">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="4_identifying_features"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('li_step.4_identifying_features')}}
                                </template>
                                <b-form-input
                                    v-model="formData.identifying_features_4"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="4" sm="4" md="4">
                            <ValidationProvider name="Identifying Features 5" vid="identifying_features_5">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="5_identifying_features"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('li_step.5_identifying_features')}}
                                </template>
                                <b-form-input
                                    v-model="formData.identifying_features_5"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <!-- <b-col lg="4" sm="4" md="4">
                            <ValidationProvider name="Identifying Features 6" vid="identifying_features_6">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="6_identifying_features"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('li_step.6_identifying_features')}}
                                </template>
                                <b-form-input
                                    v-model="formData.identifying_features_6"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col> -->
                        </b-row>
                      </slot>
                    </fieldset>
                    <div class="row mt-2">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        <!-- <b-button variant="primary" class="mr-2" @click="saveData()">{{ saveBtnName }}</b-button> -->
                        &nbsp;
                        <b-button variant="danger" class="mr-4" @click="$bvModal.hide('modal-7')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </slot>
              <slot v-else>
                <b-alert variant="danger" show dismissible fade>
                  {{$t('globalTrans.fine_taka_not_provided')}} <b>&rArr;</b>
                </b-alert>
              </slot>
            </b-overlay>
        </template>
    </iq-card>
  </b-container>
</template>
<script>
import Vue from 'vue'
import flatpickr from 'flatpickr'
import VueHtmlToPaper from 'vue-html-to-paper'
import BadcCertificateFertilizer from '@/components/license/badc/BadcCertificateFertilizer'
import UncontrolledCropVarieties from '@/components/license/moa/UncontrolledCropVarieties'
import FertilizerImportRegistration from '@/components/license/dae/FertilizerImportRegistration'
import FertilizerProductionRegistration from '@/components/license/dae/FertilizerProductionRegistration'
import FertilizerDistributorRegistration from '@/components/license/dae/FertilizerDistributorRegistration'
import BadcCertificate from '@/components/license/badc/BadcCertificate'
import commonComponent from '../../components/common/commonComponent'
import { GridLayout, GridItem } from 'vue-grid-layout'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { documentView, stepApproveIssue } from '../../api/routes'
import { wordsToSnake } from '@/Utils/fliter'
import { isNotEmpty } from '@amcharts/amcharts4/.internal/core/utils/Utils'
Vue.use(VueHtmlToPaper)
export default {
  props: ['pDatas', 'stepID'],
  components: {
    commonComponent,
    UncontrolledCropVarieties,
    FertilizerImportRegistration,
    FertilizerProductionRegistration,
    FertilizerDistributorRegistration,
    BadcCertificateFertilizer,
    BadcCertificate,
    GridLayout,
    GridItem
  },
  data () {
    return {
       saveBtnName: this.$t('component_settings.approve_issue'),
        service_id: 0,
        step_id: 0,
        application_id: [],
        paymentAmount: 0,
        service_name: '',
        step_name: '',
        applicationData: '',
        stepApproveIssue: stepApproveIssue,
        layoutData: '',
        layout: [],
        formInputData: [],
        colNum: parseInt(this.pDatas.service_id) === 1 ? 20 : 12,
        index: 0,
        unitLoad: false,
        amountSuccess: true,
        loading: false,
        componentKey: 0,
        alignment: 'A4_design_one',
        pageType: 'potrait',
        pageStyle: {
          styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'
          ]
        },
        formData: {
          service_id: parseInt(this.pDatas.service_id),
          step_id: parseInt(this.stepID),
          org_id: parseInt(this.pDatas.org_id),
          old_application_id: parseInt(this.pDatas.old_application_id),
          issue_date: new Date().toISOString().substr(0, 10),
          expire_date: '',
          note: '',
          generate_id: '',
          identifying_features_1: '',
          identifying_features_2: '',
          identifying_features_3: '',
          identifying_features_4: '',
          identifying_features_5: '',
          identifying_features_6: '',
          status: 1,
          type: 0,
          next_step_id: '',
          attachment: ''
        }
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  created () {
    this.formData.next_step_id = this.getServiceWiseStep(parseInt(this.pDatas.service_id))
    this.service_id = parseInt(this.pDatas.service_id)
    this.step_id = parseInt(this.stepID)
    this.application_id[0] = parseInt(this.pDatas.application_id)
    this.formData.application_id = this.application_id
    this.service_name = this.getServiceName(parseInt(this.pDatas.service_id))
    this.step_name = this.getStepName(parseInt(this.stepID))
    this.getApplicantDocument()
  },
  methods: {
    getApplicantDocument () {
      this.unitLoad = true
      const params = {
        service_id: this.service_id,
        step_id: this.step_id,
        application_id: parseInt(this.pDatas.application_id),
        service_name: this.service_name,
        step_name: this.step_name,
        caculation: true
      }
      RestApi.getData(licenseRegistrationServiceBaseUrl, documentView, params).then(response => {
        if (response.success) {
          this.amountSuccess = response.amountSuccess
          if (isNotEmpty(response.data)) {
            const resultData = response.data
            this.paymentAmount = response.payAmount
            this.applicationData = response.data.applicantInfo
            this.formData.expire_date = this.applicationData.expire_date
            this.formData.type = this.applicationData.type
            if (parseInt(this.service_id) === 15) {
              this.formData.identifying_features_1 = this.applicationData.identifica_3656
              this.formData.identifying_features_2 = this.applicationData._identific_1492
              this.formData.identifying_features_3 = this.applicationData._identific_9595
              this.formData.identifying_features_4 = this.applicationData._identific_3218
              this.formData.identifying_features_5 = this.applicationData._identific_1913
              this.formData.identifying_features_6 = this.applicationData._identific_1644
            }
            if (this.applicationData.old_expire_date) {
              this.formData.old_expire_date = this.applicationData.old_expire_date
            }
            if (this.applicationData.issue_date) {
              this.formData.issue_date = this.applicationData.issue_date
            }
            this.layoutData = response.data.mainLayout
            this.alignment = response.data.layout.page_size + '_' + response.data.layout.border_pattern
            const pageSize = response.data.layout.page_size
            if (pageSize === 'A4') {
              this.pageType = 'potrait'
            } else {
              this.pageType = 'landscape'
            }
            const CustomCss = `${licenseRegistrationServiceBaseUrl}${this.pageType}.css`
            this.pageStyle.styles.push(CustomCss)
            resultData.layout.document_layouts.forEach((layItem) => {
                const layJson = JSON.parse(layItem.layout)
                const dataJson = JSON.parse(layItem.data)
                this.layout.push(layJson)
                this.formInputData.push(dataJson)
            })
            this.index = this.layout.length
          }
        } else {
            this.$toast.error({
              title: 'Error',
              message: response.message
            })
            this.$bvModal.hide('modal-7')
          }
        this.unitLoad = false
      })
    },
    getServiceName (sId) {
      const service = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === sId)
      return service !== undefined ? wordsToSnake(service.text) : ''
    },
    getStepName (stId) {
      const step = this.$store.state.licenseRegistration.commonObj.stepNamesList.find(item => item.value === stId)
      return step !== undefined ? wordsToSnake(step.text) : ''
    },
    async saveData () {
      let result = null
      this.unitLoad = true
      result = await RestApi.postData(licenseRegistrationServiceBaseUrl, stepApproveIssue, this.formData)
      this.unitLoad = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$store.dispatch('licenseRegistration/setLaodList', true)
        this.$bvModal.hide('modal-7')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getServiceWiseStep (serviceId) {
        const tmpStepList = this.$store.state.licenseRegistration.commonObj.serviceSteps.filter(item => item.service_id === parseInt(serviceId))
        const tmpIndVStepList = tmpStepList.find(item => item.step_id === parseInt(this.stepID))
        if (tmpIndVStepList !== undefined) {
          const order = tmpIndVStepList.order
          const orderCheck = tmpStepList.find(item => item.order === order + 1)
          if (typeof orderCheck !== 'undefined') {
            return orderCheck.step_id
          } else {
            return parseInt(this.stepID)
          }
        } else {
           return parseInt(this.stepID)
        }
    }
  }
}
</script>
<style scoped>
.A4_none {
  width:21cm;
  margin: 20px;
}
.A4_design_one {
  width:21cm;
  height: 1090px;
  margin: auto !important;
  background: url('../../../../../assets/images/design-one-A4.jpg');
  background-size: cover;
  padding: 45px;
  /* border: 10px solid transparent;
  border-image: url('../../../../../assets/images/border.png') 30 repeat;
  border-image-width: 14px; */
}
.A4_design_two {
  width:21cm;
  margin: auto !important;
  background: url('../../../../../assets/images/design-two-A4.jpg');
  background-size: cover;
  border: 10px solid #01035c;
}
.A4_design_two::before {
  content: '';
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  border: 7px solid #564b00;
}
.A4_design_three {
  width:21cm;
  margin: auto !important;
  background: url('../../../../../assets/images/design-three-A4.jpg');
  background-size: cover;
  padding: 20px;
}
.A4_design_four {
  width:21cm;
  margin: auto !important;
  background: url('../../../../../assets/images/design-four-A4.jpg');
  background-size: cover;
}
.landscape_none {
  margin: auto !important;
}
.landscape_design_one {
  position: relative;
  margin: auto !important;
  background: url('../../../../../assets/images/design-one-landscape.jpg');
  background-size: cover;
}
.landscape_design_two {
  position: relative;
  background: url('../../../../../assets/images/design-two-landscape.jpg');
  background-size: cover;
  border: 10px solid #01035c;
  padding: 10px;
  margin: 20px;
}
.landscape_design_two::before {
  content: '';
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  border: 7px solid #564b00;
}
.landscape_design_six {
  position: relative;
  background: url('../../../../../assets/images/design-six-landscape.jpg');
  background-size: cover;
  border: 10px solid #01035c;
  padding: 10px;
  margin: 20px;
}
.landscape_design_six::before {
  content: '';
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  border: 7px solid #564b00;
}
.landscape_design_three {
  position: relative;
  margin: auto !important;
  background: url('../../../../../assets/images/design-three-landscape.jpg');
  background-size: cover;
}
.landscape_design_four {
  position: relative;
  margin: auto !important;
  background: url('../../../../../assets/images/design-four-landscape.jpg');
  background-size: cover;
}
.report-wrapper {
    font-family: "Kalpurush";
    font-size: 1rem
}
/* .A4_design_seven {
  position: relative;
  background: url('../../../../../assets/images/dae-import.jpg');
  background-size: cover;
  border: 10px solid #01035c;
  width: 20cm;
  margin: 20px;
  padding: 20px;
}
.A4_design_seven::before {
  content: '';
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  border: 7px solid #564b00;
} */
</style>
