<template>
  <b-container fluid>
    <b-overlay :show="saveLoading">
        <b-col md="12" lg="12" sm="12">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <b-form  @submit.prevent="handleSubmit(saveFormData)" >
              <div>
                <b-table-simple bordered>
                    <b-tr>
                      <b-th style="width: 20%;">{{ $t('service_name.service_name') }}</b-th>
                      <b-td style="width: 30%;text-align: left;">{{ getServiceName(service_id) }}</b-td>
                      <b-th style="width: 20%;">{{ $t('li_step.application_id') }}</b-th>
                      <b-td style="width: 30%;text-align: left;">{{ application_id }}</b-td>
                    </b-tr>
                </b-table-simple>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider name="Applicant Name(En)" vid="applicant__1807" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="applicant__1807"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                                {{ $t('li_step.applicant_name') }} {{ $t('globalTrans.enn') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="applicant__1807"
                              v-model="formData.applicant__1807"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Applicant Name (Bn)" vid="applicant__1497" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="applicant__1497"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                                {{ $t('li_step.applicant_name') }} {{ $t('globalTrans.bnn') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="applicant__1497"
                              v-model="formData.applicant__1497"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Father's Name/Husband's name (En)" vid="fathers_na_6391" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fathers_na_6391"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.father_or_husband') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="fathers_na_6391"
                              v-model="formData.fathers_na_6391"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Father's Name/Husband's name (Bn)" vid="fathers_na_9299" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fathers_na_9299"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.father_or_husband') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="fathers_na_9299"
                              v-model="formData.fathers_na_9299"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Mother Name (En)" vid="mothers_na_4511" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="mothers_na_4511"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('globalTrans.mother_name_en') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="mothers_na_4511"
                              v-model="formData.mothers_na_4511"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                   <div class="col-md-6">
                    <ValidationProvider name="Mother Name (Bn)" vid="mothers_na_5069" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="mothers_na_5069"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('globalTrans.mother_name_bn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="mothers_na_5069"
                              v-model="formData.mothers_na_5069"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Present Address (En)" vid="present_ad_5848" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="present_ad_5848"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('pusti_mapping.present_address') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="present_ad_5848"
                              v-model="formData.present_ad_5848"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                   <div class="col-md-6">
                    <ValidationProvider name="Present Address (Bn)" vid="present_ad_5362" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="present_ad_5362"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('pusti_mapping.present_address') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="present_ad_5362"
                              v-model="formData.present_ad_5362"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Permanent Address (En)" vid="permanent__7835" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="permanent__7835"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.permanent_address') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="permanent__7835"
                              v-model="formData.permanent__7835"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                   <div class="col-md-6">
                    <ValidationProvider name="Permanent Address (Bn)" vid="permanent__5521" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="permanent__5521"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.permanent_address') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="permanent__5521"
                              v-model="formData.permanent__5521"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Business Name (En)" vid="business_o_8703" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="business_o_8703"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.business_name') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="business_o_8703"
                              v-model="formData.business_o_8703"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                   <div class="col-md-6">
                    <ValidationProvider name="Business Name (Bn)" vid="business_o_1113" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="business_o_1113"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.business_name') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="business_o_1113"
                              v-model="formData.business_o_1113"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Address" vid="addresseng_3160" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="addresseng_3160"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.business_address') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="addresseng_3160"
                              v-model="formData.addresseng_3160"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                   <div class="col-md-6">
                    <ValidationProvider name="Address Bn" vid="addressban_1958" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="addressban_1958"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.business_address') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="addressban_1958"
                              v-model="formData.addressban_1958"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Mobile No" vid="national_i_6977" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="national_i_6977"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('globalTrans.nid') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="national_i_6977"
                              v-model="formData.national_i_6977"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Mobile No" vid="mobile_num_5699" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="mobile_num_5699"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('irri_pump_main.mobile_no') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="mobile_num_5699"
                              v-model="formData.mobile_num_5699"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <!-- <div class="col-md-6">
                    <ValidationProvider name="Business Class" vid="business_c_2676" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="business_c_2676"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('li_step.business_class')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="formData.business_c_2676"
                          :options="[]"
                          id="division_8106"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </div> -->
                  <div class="col-md-6">
                    <ValidationProvider name="Product Verity" vid="product_ve_4186" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="product_ve_4186"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('li_step.product_verity')}} <span class="text-danger">*</span>
                        </template>
                        <v-select
                          class="lrcpn-dd"
                          id="field_name"
                          v-model="formData.product_ve_4186"
                          :reduce="op => op.value"
                          multiple
                          :options="agricultureProductTypeList"
                          label="text"
                          :state="errors[0] ? false : (valid ? true : null)"
                          @input="changeProductInfo()"
                        >
                        </v-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Product Details" vid="product_de_4058" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="product_de_4058"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('li_step.product_details')}} <span class="text-danger">*</span>
                        </template>
                        <v-select
                          class="lrcpn-dd"
                          id="field_name"
                          v-model="formData.product_de_4058"
                          :reduce="op => op.value"
                          multiple
                          :options="agricultureProductList"
                          label="text"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        </v-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Name of Notified Market" vid="name_of_no_2818" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="name_of_no_2818"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('li_step.name_of_notified_market')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="formData.name_of_no_2818"
                          :options="marketList"
                          id="name_of_no_2818"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Image" vid="image">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('globalTrans.image')"
                        label-for="logo"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('globalTrans.image')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-file
                          id="image"
                          v-on:change="onLeftLogoChange"
                          accept="image/*"
                          v-model="formData.attachment"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        <b-img v-if="formData.applicant__3136" width='150px' :src="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(formData.applicant__3136)" fluid alt="Picture"></b-img>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <slot v-if="parseInt(formData.type) !== 1">
                    <div class="col-md-6">
                      <ValidationProvider name="Old Manual Id" vid="old_manual_id" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="old_manual_id"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('externalLrcpn.prev_license_no') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="old_manual_id"
                              v-model="formData.old_app.generate_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider name="First Issue Date" vid="issue_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="issue_date"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('externalLrcpn.first_issue') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="datepicker"
                              v-model="formData.old_app.issue_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider name="Reniew Issue Date" vid="reniew_issue_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="reniew_issue_date"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('externalLrcpn.last_renew_issue_date') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="expireDate"
                              v-model="formData.reniew_issue_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <!-- <div class="col-md-6">
                      <ValidationProvider name="Expire Date" vid="expire_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="expire_date"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('externalLrcpn.last_issue') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="expireDate1"
                              v-model="formData.old_app.expire_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </div> -->
                  </slot>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 text-right">
                  <b-button type="submit" variant="primary" class="mr-2 btn-sm">{{ $t('globalTrans.submit') }}</b-button>
                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-11')">{{ $t('globalTrans.cancel') }}</b-button>
                </div>
              </div>
            </b-form>
          </ValidationObserver>
        </b-col>
    </b-overlay>
  </b-container>
</template>
<script>
import flatpickr from 'flatpickr'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { changeCertificateDataList, certificateChangeDamStore } from '../../../api/routes'
export default {
  props: ['pDatas', 'stepID'],
  components: {
  },
  mounted () {
    flatpickr('#datepicker', {})
    flatpickr('#expireDate', {})
  },
  data () {
    return {
      saveLoading: false,
      loadHistory: false,
      application_id: this.pDatas.application_id,
      service_id: this.pDatas.service_id,
      note: 0,
      formData: {
        cc_request_id: 0,
        old_app: {
          issue_date: '',
          expire_date: '',
          generate_id: ''
        }
      },
      upload_photo: '',
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
      agricultureProductList: [],
      marketList: [],
      history: []
    }
  },
  created () {
    if (this.pDatas.product_ve_4186) {
      if (!Array.isArray(this.pDatas.product_ve_4186)) {
        const datasVal = this.pDatas.product_ve_4186
        this.pDatas.product_ve_4186 = JSON.parse(datasVal)
      }
    }
    if (this.pDatas.product_de_4058) {
      if (!Array.isArray(this.pDatas.product_de_4058)) {
        const datasVal = this.pDatas.product_de_4058
        this.pDatas.product_de_4058 = JSON.parse(datasVal)
      } else {
      }
    }
    this.marketList = this.$store.state.licenseRegistration.commonObj.marketList.filter(item => item.district_id === this.pDatas.district_6510)
    this.formData = this.pDatas
    this.changeProductInfo()
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    agricultureProductTypeList: function () {
      return this.$store.state.licenseRegistration.commonObj.agricultureProductTypeList
    }
  },
  watch: {
    'formData.division_8106': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'formData.district_3887': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  methods: {
    onLeftLogoChange (e) {
       this.getBase64(e.target.files[0]).then(res => {
        this.upload_photo = res
      })
    },
    async imageShow () {
      const pictureData = JSON.parse(this.formData.applicant__3136)
      return pictureData[0]
    },
    getFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return fileData[0]
            } else {
                return false
            }
        } else {
            return false
        }
    },
    getBase64 (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
            imgResult = reader.result
        }
        reader.onerror = function (error) {
            reject(error)
        }
        reader.onloadend = function () {
            resolve(imgResult)
        }
      })
    },
    async saveFormData () {
      let result = null
      this.saveLoading = true
      const loadinState = { loading: false, listReload: false }
      this.formData.attachment = this.upload_photo
      result = await RestApi.postData(licenseRegistrationServiceBaseUrl, certificateChangeDamStore, this.formData)

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.saveLoading = false
        // this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$store.dispatch('licenseRegistration/setLaodList', true)
        this.$bvModal.hide('modal-11')
      } else {
        this.saveLoading = false
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    changeProductInfo () {
      const productList = []
      const productVe = this.formData.product_ve_4186
      if (productVe) {
        if (productVe.length > 0) {
          productVe.forEach((item, key) => {
            const agricultureProductLists = this.$store.state.licenseRegistration.commonObj.agricultureProductList.filter(item1 => item1.product_type_id === item)
            agricultureProductLists.forEach((item1, key) => {
              productList.push(item1)
            })
          })
          this.agricultureProductList = productList
          const searchData = []
          if (this.agricultureProductList.length > 0) {
            this.agricultureProductList.forEach((item, key) => {
              const product = this.formData.product_de_4058
              product.map((item1) => {
                if (parseInt(item1) === parseInt(item.value)) {
                  searchData.push(item1)
                }
              })
            })
          }
          this.formData.product_de_4058 = searchData
        } else {
          // this.agricultureProductList = []
          this.formData.product_de_4058 = []
        }
      }
    },
    getServiceName (id) {
      const serviceType = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return serviceType !== undefined ? serviceType.text_bn : ''
      } else {
        return serviceType !== undefined ? serviceType.text_en : ''
      }
    },
    getOrganizationName (id) {
      const serviceType = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return serviceType !== undefined ? serviceType.text_bn : ''
      } else {
        return serviceType !== undefined ? serviceType.text_en : ''
      }
    },
    getOfficeTypeName (id) {
      const officeType = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return officeType !== undefined ? officeType.text_bn : ''
      } else {
        return officeType !== undefined ? officeType.text_en : ''
      }
    },
    getOfficeName (id) {
      const office = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return office !== undefined ? office.text_bn : ''
      } else {
        return office !== undefined ? office.text_en : ''
      }
    },
    async showCertificateData () {
      this.saveLoading = true
      const params = {
        application_id: this.pDatas.application_id,
        service_id: this.pDatas.service_id
      }
      await RestApi.getData(licenseRegistrationServiceBaseUrl, changeCertificateDataList, params).then(response => {
        if (response.success) {
          this.formData = response.data
        }
      })
      this.saveLoading = false
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getDistrictList (divisionId = null) {
      let districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        districtList = districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    }
  }
}
</script>
<style>
  .b-table-simple {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .b-tr:hover {
    background-color: #f5f5f5;
  }

  .b-th, .b-td {
    border: 1px solid #ddd;
    padding: 7px;
    background-color: #552323;
  }

  .b-tr:nth-child(even) {
    background-color: #643232;
  }
</style>
